.App-logo {
  /* animation: spin infinite 20s linear;
  height: 80px; */
  cursor: pointer;
}

.App-header {
  padding: 50px;
  color: white;
  text-align: center;
}

/* .App-intro {
  font-size: large;
} */

/* @keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
